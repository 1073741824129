import * as Yup from 'yup'
import { I18n } from 'react-redux-i18n'

const emailInvalid=() => (I18n.t('error.email.valid'))
const emailRequired=() => (I18n.t('error.email.required'))
const nameRequired=() => (I18n.t('error.name.required'))
const amountRequired=() => (I18n.t('error.amount.required'))
const amountPositive=() => (I18n.t('error.amount.positive'))
const recurringBoolean=() => (I18n.t('error.recurring.boolean'))
const recurringRequired=() => (I18n.t('error.recurring.required'))
const paymentRequired=() => (I18n.t('error.payment.required'))
const projectRequired=() => (I18n.t('error.project.required'))

const UserFormSchema = Yup.object().shape({
  email: Yup.string().email(emailInvalid)
            .required(emailRequired),
  name: Yup.string()
            .required(nameRequired),
  amount: Yup.string()
            .required(amountRequired)
            .test('match', 
            amountPositive, 
              function(amount) { 
                return (parseFloat(amount).toString()===amount)&&(parseFloat(amount)>0);
              }),
  recurring: Yup.boolean().typeError(recurringBoolean)
            .required(recurringRequired),
  paymentMethod: Yup.string().required(paymentRequired),
  project: Yup.string().required(projectRequired)
});


export default UserFormSchema