import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ModalTransfer from './ModalTransfer'
import ModalSMS from './ModalSMS'
import ModalPaypal from './ModalPaypal'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

class SimpleModal extends React.Component {
  state = {
    transfer: false,
    sms: false,
  };

  handleOpen = (event, key) => {
    this.setState({ [key]: true });
  };

  handleClose = (event, key) => {
    this.setState({ [key]: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="alignLeft">
        <Button onClick={(event) => this.handleOpen(event, 'transfer')}>Transfer</Button>
        <Button onClick={(event) => this.handleOpen(event, 'sms')}>SMS</Button>
        <Button onClick={(event) => this.handleOpen(event, 'paypal')}>PayPal</Button>
        <ModalTransfer handleClose={this.handleClose}
          open={this.state.transfer}
          classes={classes}
          getModalStyle={getModalStyle}
        />
        <ModalSMS handleClose={this.handleClose}
          open={this.state.sms}
          classes={classes}
          getModalStyle={getModalStyle}
        />
        <ModalPaypal handleClose={this.handleClose}
          open={this.state.paypal}
          classes={classes}
          getModalStyle={getModalStyle}
        />
        
      </div>
    );
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;