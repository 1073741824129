import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import * as actionDefs from '../../modules/actions';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import 'typeface-roboto'

const MakePayment = props => {

  const displayPaymentMessage = (props) => {
    return (
      <Paper className="paymentMessageContainer">{
          I18n.t('paymentNotifications.payment-success')
      }</Paper>
    );
  }

  return (
  <div>
    <Typography component="h3" variant="h3" gutterBottom className="menuTransparent center">
      {I18n.t('payment.title')}
    </Typography>
    { displayPaymentMessage(props)}
    
  </div>
)};

const mapStateToProps = ({ actions }) => ({
  ...actions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...actionDefs },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakePayment)
