import React from 'react'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import { I18n } from 'react-redux-i18n'

const ModalTransfer = props => {
  const {
    handleClose,
    getModalStyle,
    classes,
    open,
  } = props;
    return (
      <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(event) => handleClose(event, 'sms')}
        >
          <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="body1" gutterBottom>
            {I18n.t('sms.help')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {I18n.t('sms.send')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {I18n.t('sms.carriers')}
          </Typography>
          </div>
        </Modal>
    );
  };

export default ModalTransfer