import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const TransferInfo = props => {
  return (
  <div>
    <Typography component="h1" variant="h1" gutterBottom className="menuTransparent center">
     {I18n.t('transfer.title')}
    </Typography>
    <Typography variant="body1" gutterBottom className="menuTransparent center">
     {I18n.t('transfer.header')}
    </Typography>
    <Card className="formContainer">
      <CardContent>
        <Typography component="h3" variant="h3" gutterBottom className="center">
          {I18n.t('transfer.lei.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.beneficiary')} Asociatia MagiCAMP
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.VAT')} 33481989
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.details')} {props.paymentDetails.project}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.IBAN')} RO89INGB0000999907361027
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.BIC')} INGB
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.SWIFT')} INGBROBU
        </Typography>
      </CardContent>
    </Card>
    <Card className="formContainer">
      <CardContent>
        <Typography component="h3" variant="h3" gutterBottom className="center">
          {I18n.t('transfer.euro.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.beneficiary')} Asociatia MagiCAMP
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.VAT')} 33481989
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.details')} {props.paymentDetails.project}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.IBAN')}  RO56INGB0000999907361039
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.BIC')} INGB
        </Typography>
        <Typography variant="body1" gutterBottom>
          {I18n.t('transfer.SWIFT')} INGBROBU
        </Typography>
      </CardContent>
    </Card>
  </div>
)}

const mapStateToProps = ( { actions } ) => ({
  ...actions
})

export default connect(
  mapStateToProps
)(TransferInfo)