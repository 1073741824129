import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const FAQ = () => (
  <div>
    <Typography component="h3" variant="h3" gutterBottom className="menuTransparent center">
     {I18n.t('faq.title')}
    </Typography>
    <Card className="faqContainer">
      <CardContent>
      <Typography component="h5" variant="h5" gutterBottom className="menuTransparent center">
        {I18n.t('faq.cancel.title')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {I18n.t('faq.cancel.address')}
        <a href="mailto:contact@magicamp.ro">contact@magicamp.ro</a>
        {I18n.t('faq.cancel.info')}
      </Typography>
      <Typography component="h5" variant="h5" gutterBottom className="menuTransparent center">
        {I18n.t('faq.sms.title')}
      </Typography>
      <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: I18n.t('faq.sms.content')}} />
    </CardContent>
    </Card>
  </div>
)

const mapStateToProps = ( { actions } ) => ({
  language: actions.language
})

export default connect(
  mapStateToProps
)(FAQ)