import React from 'react'

const About = () => (
  <div>
    <h1>About Page</h1>
    <p>Did you get here via Redux?</p>
  </div>
)

export default About
