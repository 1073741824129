import request from 'superagent'
import braintree from 'braintree-web-drop-in';

import * as constants from './constants';

let brainTreeInstance;

export const createBraintreeClient = props => {
    if (typeof document === 'undefined') return;
    request
      .get(`${constants.apiUrl}/client_token`)
      .then((response) => {
        const clientToken = response.text;
        const braintreeConfig = {
          authorization: clientToken,
          container: '.dropin-container',
          threeDSecure: true
        };
        if (props.paymentMethod === 'paypal') {
          braintreeConfig.paypal = {
            flow: 'checkout',
            amount: props.amount,
            currency: 'EUR',
          };
        }
        braintree.create(braintreeConfig, (createErr, instance) => {
          console.log('assigning instance', instance)
          if(instance) brainTreeInstance = instance;
        });        
      });
  };
  

export const makeBraintreePayment = (props) => {
    const { paymentDetails: { amount, recurring, email, name, project } } = props;
    const threeDSecure = {
      amount,
      email
    };
    brainTreeInstance.requestPaymentMethod({threeDSecure}, (err, payload) => {
      props.setPaymentPending();
      if(err || !payload) {
        console.log(err.message);
        return props.setPaymentError();
      }
      request
        .post(`${constants.apiUrl}/checkout`)
        .send({
          payment_method_nonce: payload.nonce,
          amount, recurring, email, name, project
        })
        // .set('X-API-Key', 'foobar')
        .set('Accept', 'application/json')
        .end((apiErr, res) => {
          if (apiErr || !res.ok) {
            props.setPaymentError();
          } else {
            props.setPaymentSuccess();
          }
        });
      // Submit payload.nonce to your server
    });
  }
