import React from 'react'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import { I18n } from 'react-redux-i18n'
import TransferDialog from './TransferDialog'

const ModalTransfer = props => {
  const {
    handleClose,
    getModalStyle,
    classes,
    open,
  } = props;
    return (
      <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(event) => handleClose(event, 'transfer')}
        >
          <div style={getModalStyle()} className={classes.paper}>

          <TransferDialog/>
          <Typography variant="body1" gutterBottom>
            {I18n.t('transfer.header')}
          </Typography>
          </div>
        </Modal>
    );
  };

export default ModalTransfer