import React from 'react'
import { Form } from 'formik'
import { I18n } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'

import InputField from '../commons/formik/InputField'
import CheckboxField from '../commons/formik/CheckboxField'
import SelectField from '../commons/formik/SelectField'
import * as constants from '../../modules/constants'

const {  projectList } = constants;

const UserForm = props => {
  const {
    errors,
    setFieldValue,
    values
  } = props;
  return (
    <Form>
          <div>
            <SelectField setFieldValue={setFieldValue}
                      value={values.project}
                      name={"project"}
                      options = { projectList }
                      className={"textInput"}
                      label={I18n.t('form.project')}
                      error={errors.project}
                      />
          </div>
          <div>
          <InputField setFieldValue={setFieldValue}
                      value={values.name}
                      label={I18n.t('form.name')}
                      name={"name"}
                      error={errors.name}
                      className={"textInput"}
                      />
          </div>
          <div>
            <InputField setFieldValue={setFieldValue}
                        value={values.email}
                        label={I18n.t('form.email')}
                        name={"email"}
                        error={errors.email}
                        className={"textInput"}
                        />
          </div>
          <div>
            <InputField setFieldValue={setFieldValue}
                        value={values.amount}
                        label={I18n.t('form.amount')}
                        name={"amount"}
                        error={errors.amount}
                        className={"textInput"}
                        />
          </div>
          <div>
            <CheckboxField setFieldValue={setFieldValue}
                        value={values.recurring}
                        label={I18n.t('form.monthly')}
                        name={"recurring"}
                        />
          </div>
          <Button variant="outlined" type="submit">{I18n.t('form.button')}</Button>
        </Form>
  );
};

export default UserForm