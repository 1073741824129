import React from 'react'
import { Link } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { setLanguage } from '../../modules/actions'
import { bindActionCreators } from 'redux'
import Button from '@material-ui/core/Button'

const Header = (props) => {
  const setEnglish = () => {
    return props.setLanguage('en')
  }

  const setRomanian = () => {
    return props.setLanguage('ro')
  }

  const { embedded } = props;

  if(embedded) return (null);

  return (
    <div>
      <header>
        <div className="menuTransparent">
          <div className="leftMenu"> <Link to="/"><img className="headLogo" alt="Magic Logo" src="/magic_logo.png" /></Link> </div>
          <div className="rightMenu">
            <Button><Link className="menuItem" to="/">{I18n.t('application.title')}</Link></Button>
            <Button><Link className="menuItem" to="/faq">{I18n.t('faq.title')}</Link></Button>

            <Button onClick={setEnglish} color="primary">EN</Button>
            <Button onClick={setRomanian} color="primary">RO</Button>
          </div>
          <div className="clear">&nbsp;</div>
        </div>
      </header>
    </div>
  )
}

const mapStateToProps = ({ actions }) => ({
  language: actions.language,
  embedded: actions.embedded
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLanguage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
