import React from 'react'
import UserForm from './UserForm'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import * as constants from '../../modules/constants'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { I18n } from 'react-redux-i18n'

const {  projectList, defaultProject } = constants;

const ContentCards = props => {
  const {
    errors,
    setFieldValue,
    values
  } = props
  const findProject = projectList.find(function(project){
    if (project.name.toUpperCase()===values.project.toUpperCase()) {
      values.project=project.name
      return project
    }
    return null;
  })
  const selectedProject=(findProject)?findProject:defaultProject;

  const handleFbDonate = () => {
    window.open('https://www.facebook.com/donate/217610146145084/' , '_blank');
  }

  const renderMedia = () => {
    if(selectedProject.media) {
      return (
        <div>
          <Typography component="h3" variant="h3" gutterBottom className="center">
            {I18n.t('header.title')} {selectedProject.name}
          </Typography>
          <iframe
            title="MagiCamp Youtube"
            width="560"
            height="315"
            style={{marginLeft: '29px'}}
            src={"https://www.youtube.com/embed/"+selectedProject.media}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen>
          </iframe>
          <Typography  variant="body1" gutterBottom className="menuTransparent center" dangerouslySetInnerHTML={{ __html: I18n.t(selectedProject.description)}} />
        </div>
      );
    }
    return (
      <div>
        <img className="projectImg" alt="logo_ruf" src="/ruf.jpg" />
        <Button className="fbDonate" onClick={handleFbDonate} variant="contained" color="primary">
          { I18n.t('payment.fbdonate') }
        </Button>
        
      </div>
    );
  }

  return (
    <div>
      <Card className="formContainer">
        <CardContent>
          <UserForm values={values} errors={errors} setFieldValue={setFieldValue}/>
        </CardContent>
      </Card>
      <Card className="ytContainer">
        <CardContent>
          { renderMedia() }
        </CardContent>
      </Card>
    </div>
  );
};

export default ContentCards