import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'sanitize.css/sanitize.css'
import './index.css'

import {dictionaries} from '../src/i18n'

require('dotenv').config()

store.dispatch(loadTranslations(dictionaries))
store.dispatch(setLocale('ro'))
syncTranslationWithStore(store)

const target = document.querySelector('#root')

const THEME = createMuiTheme({
  typography: {
   "fontFamily": "\"Nunito\", sans-serif",
  }
});

render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>,
  target
)
