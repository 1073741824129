import store from '../store'
import { setLocale } from 'react-redux-i18n'

export const INCREMENT_REQUESTED = 'counter/INCREMENT_REQUESTED'
export const INCREMENT = 'counter/INCREMENT'
export const DECREMENT_REQUESTED = 'counter/DECREMENT_REQUESTED'
export const DECREMENT = 'counter/DECREMENT'
export const SET_PAYMENT_DETAILS = 'counter/SET_PAYMENT_DETAILS'
export const SET_LANGUAGE = 'global/SET_LANGUAGE'
export const SET_LANGUAGE_REQUESTED = 'global/SET_LANGUAGE_REQUESTED'
export const SET_PAYMENT_PENDING = 'global/SET_PAYMENT_PENDING'
export const SET_PAYMENT_SUCCESS = 'global/SET_PAYMENT_SUCCESS'
export const SET_PAYMENT_ERROR = 'global/SET_PAYMENT_ERROR'
export const SET_EMBED_MODE = 'global/SET_EMBED_MODE'

const initialState = {
  count: 0,
  isIncrementing: false,
  isDecrementing: false,
  paymentDetails: {},
  language: 'ro',
  displayBraintree: true,
  paymentNotification: null,
  embedded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_LANGUAGE:
      const { payload: { language } } = action;
      return {
        ...state,
        language
      }

    case INCREMENT_REQUESTED:
      return {
        ...state,
        isIncrementing: true
      }

    case INCREMENT:
      return {
        ...state,
        count: state.count + 1,
        isIncrementing: !state.isIncrementing
      }

    case DECREMENT_REQUESTED:
      return {
        ...state,
        isDecrementing: true
      }

    case DECREMENT:
      return {
        ...state,
        count: state.count - 1,
        isDecrementing: !state.isDecrementing
      }

    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {...action.payload},
        paymentNotification: '',
        displayBraintree: true
      }
    
    case SET_PAYMENT_PENDING:
      return {
        ...state,
        displayBraintree: false,
        paymentNotification: 'payment-pending'
      }
    
    case SET_PAYMENT_SUCCESS:
      return {
        ...state,
        displayBraintree: false,
        paymentNotification: 'payment-success'
      }

    case SET_PAYMENT_ERROR:
      return {
        ...state,
        displayBraintree: false,
        paymentNotification: 'payment-error'
      }
    
    case SET_EMBED_MODE:
      return {
        ...state,
        embedded: true,
      }

    default:
      return state
  }
}

export const setLanguage = (language) => {
  return dispatch => {
    store.dispatch(setLocale(language))

    dispatch({
      type: SET_LANGUAGE_REQUESTED,
      payload: { language }
    })

    dispatch({
      type: SET_LANGUAGE,
      payload: { language }
    })
  }
}

export const increment = () => {
  return dispatch => {
    dispatch({
      type: INCREMENT_REQUESTED
    })

    dispatch({
      type: INCREMENT
    })
  }
}

export const incrementAsync = () => {
  return dispatch => {
    dispatch({
      type: INCREMENT_REQUESTED
    })

    return setTimeout(() => {
      dispatch({
        type: INCREMENT
      })
    }, 3000)
  }
}

export const decrement = () => {
  return dispatch => {
    dispatch({
      type: DECREMENT_REQUESTED
    })

    dispatch({
      type: DECREMENT
    })
  }
}

export const decrementAsync = () => {
  return dispatch => {
    dispatch({
      type: DECREMENT_REQUESTED
    })

    return setTimeout(() => {
      dispatch({
        type: DECREMENT
      })
    }, 3000)
  }
}

export const setPaymentDetails = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_PAYMENT_DETAILS, 
      payload
    })
  }
}

export const setEmbedMode = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_EMBED_MODE, 
      payload
    })
  }
}

export const setPaymentPending = () => {
  return dispatch => {
    dispatch({
      type: SET_PAYMENT_PENDING
    })
  }
}

export const setPaymentSuccess = () => {
  return dispatch => {
    dispatch({
      type: SET_PAYMENT_SUCCESS
    })
  }
}

export const setPaymentError = () => {
  return dispatch => {
    dispatch({
      type: SET_PAYMENT_ERROR
    })
  }
}