module.exports = {
  apiUrl: process.env.REACT_APP_API_URL,
  braintreeToken: process.env.REACT_APP_TOKEN,
  projectList: [
    {
      name: 'Maini Magice',
      media: 'Ky5Vk_Jqf5Q',
      description: 'projectDescriptions.MagicHands'
    },
    {
      name: 'MagiCAMP',
      media: 'lO0eonLasH0',
      description: 'projectDescriptions.MagiCAMP'
    },
    {
      name: 'MagicHOME',
      media: 'YkJ_Qmg0DxE',
      description: 'projectDescriptions.MagicHOME'
    },
    {
      name: 'MagicBOX',
      media: 'YLuOX9LzixU',
      description: 'projectDescriptions.MagicBOX'
    },
    {
      name: 'MagicHELP',
      media: 'Ohug3pNOf2s',
      description: 'projectDescriptions.MagicHELP'
    },
    {
      name: 'MagicEDU',
      media: 'mRrhnMb0EKU',
      description: 'projectDescriptions.MagicEDU'
    }
  ],
  defaultProject: {
    name: 'Asociatia Magic',
    media: 'Ky5Vk_Jqf5Q',
    description: 'projectDescriptions.MagicHands'
  },
  paymentMethodList: ['card', 'transfer'],
  privacyPolicy: 'http://magicamp.ro/politica-de-confidentialitate/',
  cookiePolicy: 'http://magicamp.ro/cookie-policy/'
}
