import React from 'react'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  setPaymentDetails
} from '../../modules/actions'
import ContentCards from './ContentCards'
import UserFormSchema from './ValidationSchema'
import * as constants from '../../modules/constants'

const {  defaultProject } = constants;

const mapStateToProps = ({ actions }) => ({
  ...actions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPaymentDetails
    },
    dispatch
  )

const FormikComponent = props => (
 <div>
    <Formik
      validationSchema={UserFormSchema}
      initialValues={{
        amount: '2',
        email: '',
        name: '',
        recurring: true,
        paymentMethod: 'card',
        project: (props.projectRoute)?props.projectRoute:defaultProject.name
      }}
      onSubmit={values => {
        props.submitPaymentDetails(values);
      }}
      render={({
        errors,
        setFieldValue,
        values,
      }) => (
       <ContentCards values={values} errors={errors} setFieldValue={setFieldValue}/>
      )}
    />
    
  </div>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormikComponent)