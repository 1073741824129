import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionDefs from '../../modules/actions'
import FormikComponent from './FormikComponent'
import 'typeface-roboto'
import SimpleModalWrapped from '../commons/modal/SimpleModalWrapped'

const Home = props => { 
  const submitPaymentDetails = (values) => {
    const { setPaymentDetails } = props;
    setPaymentDetails(values);
    props.changePage(values.paymentMethod);
  }

  const { match: {params: { embedded }}, setEmbedMode} = props;

  if(embedded) setEmbedMode();

  return (
  <div>
    <SimpleModalWrapped/>
    <FormikComponent submitPaymentDetails={submitPaymentDetails} projectRoute={props.match.params.project}/>
  </div>
)};

const mapStateToProps = ({ actions }) => ({
  ...actions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionDefs,
      changePage: (nextPage) => push(nextPage?`/${nextPage}`:'/card')
    },
    dispatch
  )

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
