import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { setLanguage } from '../../modules/actions'
import { bindActionCreators } from 'redux'
import constants from '../../modules/constants'
import Button from '@material-ui/core/Button'

const Footer = (props) => {
  const onPrivacyPolicy = () => {
    window.open(constants.privacyPolicy, "_blank");
  }

  const onCookiePolicy = () => {
    window.open(constants.cookiePolicy, "_blank");
  }

  const { embedded } = props;

  if(embedded) return (null);

  return (
    <div>
      <footer>
        <div className="footer">
        <Button size="large" onClick={onPrivacyPolicy} >
          {I18n.t('application.privacy_policy')}
        </Button>
        <Button size="large" onClick={onCookiePolicy}>
          {I18n.t('application.cookie_policy')}
        </Button>
        </div>
      </footer>
    </div>
  )
}

const mapStateToProps = ({ actions }) => ({
  language: actions.language,
  embedded: actions.embedded
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLanguage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
