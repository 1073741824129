import React from 'react'
import Modal from '@material-ui/core/Modal'

const ModalPaypal = props => {
  const {
    handleClose,
    getModalStyle,
    classes,
    open,
  } = props;
    return (
      <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={(event) => handleClose(event, 'paypal')}
        >
          <div style={getModalStyle()} className={classes.paper}>
          <div className="paypal-wrapper">
          <h3 style={{marginBottom: '5px'}}>Donaţie lunară cu cardul</h3>
          <form style={{marginTop: '5px'}} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input name="cmd" type="hidden" value="_s-xclick" />
          <input name="hosted_button_id" type="hidden" value="69EBCSUDHWCA8" />
          <table>
          <tbody>
          <tr>
          <td><input name="on0" type="hidden" value="" /></td>
          </tr>
          <tr>
          <td><select name="os0">
          <option value="Option 1">Option 1 : €5.00 EUR - monthly</option>
          <option value="Option 2">Option 2 : €10.00 EUR - monthly</option>
          <option value="Option 3">Option 3 : €25.00 EUR - monthly</option>
          </select></td>
          </tr>
          </tbody>
          </table>
          <input name="currency_code" type="hidden" value="EUR" />
          <input alt="PayPal - The safer, easier way to pay online!" name="submit" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" type="image" />
          <img src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" alt="" width="1" height="1" border="0" />
          
          </form>
          <h3 style={{marginBottom: '5px'}}>Donaţie singulară cu cardul</h3>
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank"><input name="cmd" type="hidden" value="_s-xclick" /><input name="hosted_button_id" type="hidden" value="2J9PGPTX33GY8" /><input alt="PayPal - The safer, easier way to pay online!" name="submit" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" type="image" /><img src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" alt="" width="1" height="1" border="0" />
          
          <img src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" alt="" width="1" height="1" border="0" />
          
          </form></div>
          </div>
        </Modal>
    );
  };

export default ModalPaypal