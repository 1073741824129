import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import TransferTab from './TransferTab'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  }
});

class TransferDialog extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange} className="tablist">
            <Tab label="BCR"/>
            <Tab label="UniCredit Bank"/>
          </Tabs>
        </AppBar>
        {value === 0 && 
          <TransferTab 
            accountEUR={"RO93RNCB0132142511010002"}
            accountRON={"RO23RNCB0132142511010001"}
            bic={"RNCB"}
            swift={"RNCBROBU"}
          />
        }
        {value === 1 && 
          <TransferTab 
            accountEUR={"RO89BACX0000001062715001"}
            accountRON={"RO19BACX0000001062715000"}
            bic={"BACX"}
            swift={"BACXROBU"}
          />
        }
      </div>
    );
  }
}

TransferDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransferDialog);