import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { createBraintreeClient, makeBraintreePayment } from '../../modules/helpers'
import * as actionDefs from '../../modules/actions';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { push } from 'connected-react-router'
import 'typeface-roboto'

const MakePayment = props => {

  if(props.paymentNotification === 'payment-success') {
    props.onSuccess('/multumim');
    return null;
  }

  const handleRequestClick = () => {
    makeBraintreePayment(props)
  }

  const displayBraintreeContainer = (props) => {
    if(!props.displayBraintree) return;
  
    return (
      <Card className="cardContainer">
        <CardContent>
          <div className="dropin-container" />
          <Button variant="contained" color="secondary" onClick={handleRequestClick} type="submit">{I18n.t('payment.button')}</Button>
        </CardContent>
      </Card>
    );
  }

  const displayPaymentMessage = (props) => {
    if(!props.paymentNotification) return;

    return (
      <Paper className="paymentMessageContainer">{
        props.paymentNotification ?
        I18n.t('paymentNotifications.'+props.paymentNotification) :
        ''
      }</Paper>
    );
  }

  createBraintreeClient(props);

  return (
  <div>
    <Typography component="h3" variant="h3" gutterBottom className="menuTransparent center">
      {I18n.t('payment.title')}
    </Typography>
    { displayPaymentMessage(props)}
    { displayBraintreeContainer(props) }
    
  </div>
)};

const mapStateToProps = ({ actions }) => ({
  ...actions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...actionDefs, onSuccess: () => push('/multumim') },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakePayment)
