import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField'

const InputField = props => {
  const {
    error,
    setFieldValue,
    value,
    name,
    label,
    className
  } = props;
  return (
      <Field
          validateOnBlur
          validateOnChange
          name={name}
          render={({ field, form }) => (
            <TextField
                id={"standard-"+name}
                label={label}
                value={value}
                margin="normal"
                error={
                  Boolean(error)
                }
                onChange={event => {
                  setFieldValue(name, event.target.value)
                }}
                helperText={error}
                className={className}
              />
          )}
      />      
  );
};

export default InputField