import React from 'react'
import { Field } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import NativeSelect from '@material-ui/core/NativeSelect'

const SelectField = props => {
  const {
    setFieldValue,
    value,
    className,
    name,
    label,
    options,
    error
  } = props;
  var projectList = options.map(function(option){
    return <option value={option.name}>{option.name}</option>;
  });
  return (
      <Field
          validateOnBlur
          validateOnChange
          name={name}
          render={({ field, form }) => (
            <FormControl 
              className={className}                 
              error={
                Boolean(error)
              }>
              <InputLabel htmlFor={"standard-"+name}>{label}</InputLabel>
              <NativeSelect
                value={value}
                onChange={event => {
                  setFieldValue(name, event.target.value)
                }}
                input={<Input id={"standard-"+name} />}
                >
                <option value="" disabled/>
                { projectList }
              </NativeSelect>
            </FormControl>
          )}
      />      
  );
};

export default SelectField