import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../home'
import About from '../about'
import FAQ from '../faq'
import Header from './header'
import Footer from './footer'
import MakePayment from '../make-payment'
import Success from '../success'
import TransferInfo from '../transfer'

const App = () => (
  <div>
    <Header />
    <main>
      <Route exact path="/" component={Home} />
      <Route exact path="/embedded/:embedded" component={Home} />
      <Route exact path="/project/:project?" component={Home} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/card" component={MakePayment} />
      <Route exact path="/multumim" component={Success} />
      <Route exact path="/transfer" component={TransferInfo}  />
    </main>
    <div className="clear">&nbsp;</div>
    <Footer />
  </div>
)

// const mapStateToProps = ( { actions } ) => ({
//   language: actions.language
// })

export default App