import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { I18n } from 'react-redux-i18n'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const TransferTab = props => {
  const {
    accountEUR,
    accountRON,
    bic,
    swift
  } = props;
  return (
    <TabContainer>
      <Typography variant="body1" gutterBottom>
        {I18n.t('transfer.euro.title')} {accountEUR}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {I18n.t('transfer.lei.title')} {accountRON}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {I18n.t('transfer.BIC')} {bic}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {I18n.t('transfer.SWIFT')} {swift}
      </Typography>
    </TabContainer>
  );
};

export default TransferTab;